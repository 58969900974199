import { Box, Chip, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { CPTCode } from 'node-api/scribe/medical';
import {
  DiagnosisSummaryContentType,
  DiagnosisSummaryOutput,
  EvaluationAndManagementByCPTCode,
  MeetingDurationContentType,
  MeetingSummaryStage,
} from 'node-api/scribe/scribe.types';
import { SectionProps } from './render-sections';

export const DiagnosisSummarySection = ({
  content,
  stage,
  handlers,
  conditions,
}: SectionProps<
  DiagnosisSummaryContentType & { meetingDurationContent: MeetingDurationContentType }
>) => {
  const conditionsByCodeOrName = conditions.reduce(
    (acc, cond) => {
      return {
        ...acc,
        codes: {
          ...acc.codes,
          [cond.condition.icd10Code]: cond.condition.name,
        },
        names: {
          ...acc.names,
          [cond.condition.name]: cond.condition.name,
        },
      };
    },
    { codes: {}, names: {} } as {
      codes: { [code: string]: string };
      names: { [name: string]: string };
    }
  );

  return (
    <Box className='edit-section' mb={3}>
      <Typography mb={1} fontWeight='600'>
        Diagnosis
      </Typography>

      <Typography>
        During this encounter, I spent approximately{' '}
        <Select
          disabled={stage === MeetingSummaryStage.PreVisit}
          key={content.meetingDurationContent.data.cptCode}
          id='diagnosis-duration-select'
          size='small'
          sx={{ fontSize: '14px', borderRadius: '40px', height: '1.5rem' }}
          value={content.meetingDurationContent.data.cptCode}
          inputProps={{
            id: 'diagnosis-duration-select-input',
          }}
          onChange={(v) => {
            const { meetingDurationRange } =
              EvaluationAndManagementByCPTCode[Number(v.target.value)];
            handlers.handleChangeMeetingDuration({
              ...content.meetingDurationContent,
              data: {
                ...content.meetingDurationContent.data,
                cptCode: v.target.value as CPTCode,
                meetingDurationRange,
                meetingDuration: meetingDurationRange,
              },
            });
          }}>
          {Object.entries(EvaluationAndManagementByCPTCode).map(([cptCode, evaluationBy]) => (
            <MenuItem
              key={`${cptCode}-${evaluationBy.meetingDurationRange}`}
              value={Number(cptCode)}>
              {`${evaluationBy.meetingDurationRange}`}
            </MenuItem>
          ))}
        </Select>{' '}
        addressing the following:
      </Typography>

      <Typography mb={2} color='#979797'>
        Select up to 4 diagnosis for each field
      </Typography>

      {Object.entries(content.data).map(([premise]) => {
        const diagnosisPremise = premise.replace(/([A-Z])/g, ' $1');

        return (
          <Box className='diagnosis-section-item'>
            <Typography>
              {`${diagnosisPremise[0].toUpperCase() + diagnosisPremise.slice(1)}`}
            </Typography>
            <Select
              disabled={stage === MeetingSummaryStage.PreVisit}
              size='small'
              fullWidth
              multiple
              value={content.data?.[premise as keyof Omit<DiagnosisSummaryOutput, 'sectionId'>]}
              onChange={(v) =>
                handlers.handleChangeDiagnosis({
                  data: v.target.value as string[],
                  premise: premise as keyof Omit<DiagnosisSummaryOutput, 'sectionId'>,
                })
              }
              input={<OutlinedInput label='Chip' />}
              renderValue={(selected) => {
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          conditionsByCodeOrName.codes[value] || conditionsByCodeOrName.names[value]
                        }
                        onDelete={() =>
                          handlers.handleDeleteDiagnosis(
                            premise as keyof Omit<DiagnosisSummaryOutput, 'sectionId'>,
                            value
                          )
                        }
                        onMouseDown={(event) => event.stopPropagation()}
                        sx={{
                          backgroundColor: '#1A667A',
                          color: 'white',
                          '> svg.MuiChip-deleteIcon': {
                            color: '#9FC9D5',
                          },
                        }}
                      />
                    ))}
                  </Box>
                );
              }}>
              {conditions.map((cond) => {
                return (
                  <MenuItem key={cond.condition.id} value={cond.condition.icd10Code}>
                    {cond.condition.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        );
      })}
    </Box>
  );
};
