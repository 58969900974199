import { Box, Typography } from '@mui/material';
import { ConditionWithRelations } from 'interfaces/health-data';
import {
  AppointmentSummaryContentType,
  DiagnosisSummaryContentType,
  DiagnosisSummaryOutput,
  MedicalProblemOutput,
  MedicalProblemsContentType,
  MedicalRemainingProblemsContentType,
  MeetingContextContentType,
  MeetingDurationContentType,
  MeetingSummaryStage,
  ScribeOutputType,
  StaticTextContentType,
  SummaryContentType,
} from 'node-api/scribe/scribe.types';
import '../../css/note-edition.css';
import { AppointmentSummarySection } from './AppointmentSummarySection';
import { DiagnosisSummarySection } from './DiagnosisSummarySection';
import { MedicalProblemSection, ProblemOption } from './MedicalProblemSection';
import { MeetingContextSection } from './MeetingContextSection';
import { MeetingDurationSection } from './MeetingDurationSection';
import { MeetingRemainingProblems } from './MeetingRemainingProblems';
import { StaticTextSection } from './StaticTextSection';

export type SectionProps<T> = {
  content: T;
  stage: MeetingSummaryStage;
  handlers: NoteEditionHandlers;
  conditions: ConditionWithRelations[];
};

export type NoteEditionHandlers = {
  handleChangeAppointmentSummary: (value: AppointmentSummaryContentType) => void;
  handleChangeMeetingContext: (value: MeetingContextContentType) => void;
  handleChangeMeetingDuration: (value: MeetingDurationContentType) => void;
  handleDeleteDiagnosis: (
    premise: keyof Omit<DiagnosisSummaryOutput, 'sectionId'>,
    value: string
  ) => void;
  handleChangeMedicalProblem: (value: {
    sectionId: string;
    order: number;
    outputType: ScribeOutputType.MedicalProblems;
    data: MedicalProblemOutput;
    index: number;
  }) => void;
  handleChangeMedicalRemainingProblems: (value: MedicalRemainingProblemsContentType) => void;
  handleChangeDiagnosis: (value: {
    premise: keyof Omit<DiagnosisSummaryOutput, 'sectionId'>;
    data: string[];
  }) => void;
  handleAddProblem: (problem: ProblemOption) => void;
  handleDeleteProblem: (problemPosition: number) => void;
  handleChangeTextSection: (value: StaticTextContentType) => void;
};

export const renderNoteEditionSectionsByOutputType = (
  outputTypes: ScribeOutputType[],
  data: SummaryContentType[],
  stage: MeetingSummaryStage,
  handlers: NoteEditionHandlers,
  conditions: ConditionWithRelations[]
): JSX.Element[] => {
  const sections = data.filter((section) => outputTypes.includes(section.outputType));

  return sections.map((section) => {
    switch (section.outputType) {
      case ScribeOutputType.MeetingContext:
        return (
          <MeetingContextSection
            conditions={conditions}
            content={section as MeetingContextContentType}
            stage={stage}
            handlers={handlers}
          />
        );

      case ScribeOutputType.MeetingDuration:
        return (
          <MeetingDurationSection
            conditions={conditions}
            content={section as MeetingDurationContentType}
            stage={stage}
            handlers={handlers}
          />
        );

      case ScribeOutputType.MedicalProblems:
        return (
          <MedicalProblemSection
            conditions={conditions}
            content={section as MedicalProblemsContentType}
            stage={stage}
            handlers={handlers}
          />
        );

      case ScribeOutputType.DiagnosisSummary: {
        const diagnosisContent = {
          ...section,
          meetingDurationContent: data.find(
            (s) => s.outputType === ScribeOutputType.MeetingDuration
          ),
        } as DiagnosisSummaryContentType & {
          meetingDurationContent: MeetingDurationContentType;
        };

        return (
          <DiagnosisSummarySection
            conditions={conditions}
            content={diagnosisContent}
            stage={stage}
            handlers={handlers}
          />
        );
      }

      case ScribeOutputType.AppointmentSummary:
        return (
          <AppointmentSummarySection
            conditions={conditions}
            content={section}
            stage={stage}
            handlers={handlers}
          />
        );

      case ScribeOutputType.MedicalRemainingProblems:
        return (
          <MeetingRemainingProblems
            conditions={conditions}
            content={section}
            stage={stage}
            handlers={handlers}
          />
        );

      case ScribeOutputType.StaticText:
        return (
          <StaticTextSection
            conditions={conditions}
            content={section}
            stage={stage}
            handlers={handlers}
          />
        );

      default:
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#F39291',
              color: 'white',
              width: '100%',
              margin: '1em 0 1em 0',
            }}>
            <Typography fontWeight={500}>
              There was an error showing the information (Section type:{' '}
              {(section as { outputType: string }).outputType})
            </Typography>
          </Box>
        );
    }
  });
};
