import { CopyAll } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { getFormattedDateFromISOString } from 'helpers/date';
import { ConditionWithRelations } from 'interfaces/health-data';
import { NoteDetail } from 'node-api/scribe/scribe.types';
import './node-edition-preview.css';
import { renderSectionsByOutputType } from './section';

export type Props = {
  conditions: ConditionWithRelations[];
  note: NoteDetail;
  onCancel: () => void;
  copyNoteContent: () => void;
};

export const NoteEditionPreview = ({ conditions, note, copyNoteContent, onCancel }: Props) => {
  return (
    <Box className='note-edition-preview-container'>
      <Box className='note-edition-content-header'>
        <Typography
          component='h4'
          fontWeight={600}
          color='#353535'
          sx={{
            fontSize: '18px !important',
          }}>
          Preview
        </Typography>

        <Box>
          <Button size='small' onClick={onCancel} className='action-button'>
            Back to preview
          </Button>
          <Button
            size='small'
            startIcon={<CopyAll />}
            onClick={copyNoteContent}
            className='action-button'>
            Copy
          </Button>
        </Box>
      </Box>

      <Box className='note-edition-content'>
        {note.content
          ?.sort((a, b) => a.order - b.order)
          .map((section) => {
            return renderSectionsByOutputType(section, conditions);
          })}

        {note.meetingSummary?.signatureHistory?.map(({ signedBy, signedOn }) => {
          return (
            <Typography>{`Signed by ${signedBy.firstName} ${signedBy.lastName}, ${
              signedBy.specialty
            } at ${getFormattedDateFromISOString(signedOn, 'MM/DD/YYYY h:mm A z')}`}</Typography>
          );
        })}
      </Box>
    </Box>
  );
};
