import { Box, TextField, Typography } from '@mui/material';
import { MeetingSummaryStage, StaticTextContentType } from 'node-api/scribe/scribe.types';
import { SectionProps } from './render-sections';

export const StaticTextSection = ({
  content,
  stage,
  handlers,
}: SectionProps<StaticTextContentType>) => {
  return (
    <Box className='edit-section' mb={3}>
      <Typography mb={1} fontWeight='600'>
        Text:
      </Typography>

      <TextField
        disabled={stage === MeetingSummaryStage.PreVisit}
        multiline
        value={content.data.text}
        fullWidth
        onChange={(v) =>
          handlers.handleChangeTextSection({
            ...content,
            data: {
              ...content.data,
              text: v.target.value,
            },
          })
        }
      />
    </Box>
  );
};
