import axios, { AxiosInstance } from 'axios';
import { FindAllConditionsParams, FindAllConditionsResponse } from '../interfaces/health-data';
import AuthService from './auth-service';

const HEALTH_DATA_BASE_URL = process.env.REACT_APP_PUBLIC_HEALTH_DATA_BASE_URL;

class HealthDataService {
  private axios: AxiosInstance;

  constructor() {
    this.axios = axios.create({
      baseURL: HEALTH_DATA_BASE_URL,
      headers: {
        Authorization: `Bearer ${AuthService.getAuth()?.Authorization.token}`,
      },
    });
  }

  public async findAllConditions(
    params: FindAllConditionsParams
  ): Promise<FindAllConditionsResponse | null> {
    try {
      const { conditions, pagination } = (
        await this.axios.get<FindAllConditionsResponse>(`/medical-setup/conditions`, {
          params,
        })
      ).data;

      return {
        conditions,
        pagination,
      };
    } catch (error) {
      console.error(error);
    }

    return null;
  }
}

const healthDataService = new HealthDataService();

export default healthDataService;
