import { Box, List, ListItem, Typography } from '@mui/material';
import { getAge, getFormattedDateFromISOString } from 'helpers/date';
import { ConditionWithRelations } from 'interfaces/health-data';
import {
  AppointmentSummaryContentType,
  DiagnosisSummaryContentType,
  MedicalProblemsContentType,
  MedicalRemainingProblemsContentType,
  MeetingContextContentType,
  MeetingContextLocation,
  MeetingDurationContentType,
  ScribeOutputType,
  StaticTextContentType,
  SummaryContentType,
} from 'node-api/scribe/scribe.types';
import { camelCaseToSentence } from 'utils/stringHelpers';
import { ENABLED_TO_SHOW_IN_NOTES } from '../NotePreview/format';

const EMPTY_SYMBOL = '___';

const renderDiagnosisSummarySection = (content: DiagnosisSummaryContentType) => {
  const dataEntries = Object.entries(content.data);
  const withData = dataEntries.some(([_premise, diagnoses]) => diagnoses.length > 0);

  return (
    <Box mb={2}>
      <Typography>
        During this encounter, I&apos;m addressing the following:{' '}
        {!withData && <strong>{EMPTY_SYMBOL}</strong>}
      </Typography>

      {withData &&
        dataEntries.map(([premise, diagnoses]) => {
          if (!Array.isArray(diagnoses) || diagnoses.length === 0) {
            return [];
          }

          const diagnosisPremise = premise.replace(/([A-Z])/g, ' $1');

          return (
            <List sx={{ listStyleType: 'disc', paddingLeft: '1em' }}>
              <ListItem sx={{ display: 'list-item', listStyle: 'disc', paddingLeft: '5px' }}>
                {`${
                  diagnosisPremise[0].toUpperCase() + diagnosisPremise.slice(1)
                }: ${diagnoses.join(', ')}`}
              </ListItem>
            </List>
          );
        })}
    </Box>
  );
};

const renderMedicalProblemsSection = (content: MedicalProblemsContentType) => {
  return (
    <>
      <Typography mb={2}>
        <strong>Problems</strong>
      </Typography>

      {content.data.map((problem) => {
        const { labs, exams, ...otherDataReviewSections } = problem.dataReview;
        return (
          <Box mb={2}>
            <Typography mb={2} fontWeight={600}>
              {`${problem.name} - ${problem.icd10Code}`}
            </Typography>

            <Typography pl={4}>History:</Typography>
            <Typography mb={2} pl={6}>
              {problem?.history}
            </Typography>
            <Typography pl={4}>Data Review:</Typography>
            <Typography mb={2} pl={6}>
              <Typography textTransform='capitalize' fontWeight={600} mb={1}>
                Labs
              </Typography>

              {labs.map((data) => {
                return (
                  <Box>
                    <Box pl={1}>
                      <Typography>{`${data.observation}: ${data.value} (${
                        data.resultDate
                          ? getFormattedDateFromISOString(data.resultDate, 'YYYY-MM-DD')
                          : 'N/A'
                      })`}</Typography>
                      <br />
                    </Box>
                  </Box>
                );
              })}

              {/*
              // TODO: Uncomment this code when we have the medical configuration ready to associate them with the medical problems section
              <Typography textTransform='capitalize' fontWeight={600} mb={1}>
                Exams
              </Typography>

              {exams.map((data) => {
                return (
                  <Box>
                    <Box pl={1}>
                      <Typography>{`${data.procedure}: ${data.status} (${
                        data.date ? getFormattedDateFromISOString(data.date, 'YYYY-MM-DD') : 'N/A'
                      })`}</Typography>
                      <br />
                    </Box>
                  </Box>
                );
              })}
              */}

              {ENABLED_TO_SHOW_IN_NOTES &&
                Object.entries(otherDataReviewSections ?? {}).map(([section, data]) => {
                  return (
                    <Box>
                      <Typography textTransform='capitalize' fontWeight={600} mb={1}>
                        {section}
                      </Typography>
                      {data.map((d) => {
                        return (
                          <Box pl={1}>
                            {Object.entries(d).map(([key, value]) => {
                              return (
                                <Typography>{`${camelCaseToSentence(key)}: ${value}`}</Typography>
                              );
                            })}
                            <br />
                          </Box>
                        );
                      })}
                    </Box>
                  );
                })}
            </Typography>
            <Typography pl={4}>Subjective Updates:</Typography>
            <Typography mb={2} pl={6}>
              {problem?.subjectiveUpdates}
            </Typography>
            <Typography pl={4}>Assessments and Discussion:</Typography>
            <Typography mb={2} pl={6}>
              {problem?.assessmentAndDiscussion}
            </Typography>
            <Typography pl={4}>Plan:</Typography>
            <Typography mb={2} pl={6}>
              {problem?.plan}
            </Typography>
          </Box>
        );
      })}
    </>
  );
};

const renderMedicalRemainingProblemsSection = (content: MedicalRemainingProblemsContentType) => {
  return (
    <Box mb={2}>
      <Typography mb={2} textAlign='justify'>
        The remaining problems were not addressed today. During this encounter, I reviewed the data
        point mentioned in the clinical note, which included face to face and time spent:{' '}
        <strong>{content.data.medicalProcedures?.join(', ') || EMPTY_SYMBOL}</strong>. I also
        additionally addressed the following problems:{' '}
        <strong>{content.data.additionalProblem || EMPTY_SYMBOL}</strong>. Based on CMS definitions,
        I estimate that risk of treatment and diagnostic plan is{' '}
        <strong>{content.data.mdmCode || EMPTY_SYMBOL}</strong>.
      </Typography>
    </Box>
  );
};

const renderMeetingContextSection = (
  content: MeetingContextContentType,
  conditions: ConditionWithRelations[]
) => {
  const matchingCondition = conditions.find(
    (cond) => cond.condition.icd10Code === content.data.mainProblemCode
  );

  return (
    <Typography mb={2} textAlign='justify'>
      {content.data.patientName} is a {getAge(content.data.patientDOB)} year old{' '}
      {content.data.patientGender} who is here for an Initial Evaluation and Assessment for{' '}
      <strong>{matchingCondition ? matchingCondition.condition.name : EMPTY_SYMBOL}</strong> . This
      visit was conducted {content.data.appointmentLocation}.{' '}
      {content.data.appointmentLocation === MeetingContextLocation.Telehealth && (
        <>
          The patient gave informed consent for telehealth during registration, including rights,
          services, and privacy measures, willingly participating in the virtual consultation.
        </>
      )}
    </Typography>
  );
};

const renderMeetingDurationSection = (content: MeetingDurationContentType) => {
  return (
    <Typography mb={2} textAlign='justify'>
      During this encounter, I spent approximately{' '}
      <strong>{content.data.meetingDurationRange}</strong>.
    </Typography>
  );
};

const renderAppointmentSummarySection = (content: AppointmentSummaryContentType) => {
  return (
    <Box mb={2}>
      <Typography>Originating site: {content.data.originatingSite}</Typography>
      <Typography>Distant site: {content.data.distantSite}</Typography>
      <Typography>
        Time started: {getFormattedDateFromISOString(content.data.startingTime, 'hh:mm')}
      </Typography>
      <Typography>
        Time ended: {getFormattedDateFromISOString(content.data.endingTime, 'hh:mm')}
      </Typography>
    </Box>
  );
};

const renderStaticTextSection = (content: StaticTextContentType) => {
  return (
    <Typography mb={2} textAlign='justify'>
      {content.data.text}
    </Typography>
  );
};

export const renderSectionsByOutputType = (
  data: SummaryContentType,
  conditions: ConditionWithRelations[]
) => {
  const { outputType } = data;

  switch (outputType) {
    case ScribeOutputType.MeetingContext:
      return renderMeetingContextSection(data as MeetingContextContentType, conditions);

    case ScribeOutputType.MeetingDuration:
      return renderMeetingDurationSection(data as MeetingDurationContentType);

    case ScribeOutputType.MedicalProblems:
      return renderMedicalProblemsSection(data as MedicalProblemsContentType);

    case ScribeOutputType.MedicalRemainingProblems:
      return renderMedicalRemainingProblemsSection(data as MedicalRemainingProblemsContentType);

    case ScribeOutputType.DiagnosisSummary:
      return renderDiagnosisSummarySection(data as DiagnosisSummaryContentType);

    case ScribeOutputType.AppointmentSummary:
      return renderAppointmentSummarySection(data as AppointmentSummaryContentType);

    case ScribeOutputType.StaticText:
      return renderStaticTextSection(data as StaticTextContentType);

    default:
      return <>There is no support to display this section (type: {`${outputType}`})</>;
  }
};
