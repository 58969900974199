import { Box, MenuItem, Select, Typography } from '@mui/material';
import { getAge } from 'helpers/date';
import { MedicalProblemCode } from 'node-api/scribe/medical';
import {
  MeetingContextContentType,
  MeetingContextLocation,
  MeetingSummaryStage,
} from 'node-api/scribe/scribe.types';
import { SectionProps } from './render-sections';

export const MeetingContextSection = ({
  content,
  stage,
  handlers,
  conditions,
}: SectionProps<MeetingContextContentType>) => {
  const renderMainProblemSelector = () => {
    return (
      <Select
        disabled={stage === MeetingSummaryStage.PreVisit}
        id='meeting-context-problem-select'
        size='small'
        sx={{ fontSize: '14px', borderRadius: '40px', height: '1.5rem' }}
        value={content.data.mainProblemCode}
        inputProps={{
          id: 'diagnosis-duration-select-input',
        }}
        onChange={(v) => {
          handlers.handleChangeMeetingContext({
            ...content,
            data: {
              ...content.data,
              mainProblemCode: v.target.value as MedicalProblemCode,
            },
          });
        }}>
        {conditions.map((cond) => (
          <MenuItem key={`context-${cond.condition.id}`} value={cond.condition.icd10Code}>
            {cond.condition.name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  return (
    <Box className='edit-section' mb={3}>
      <Typography fontWeight='600' mb={1}>
        Context
      </Typography>

      <Typography textAlign='justify'>
        {content.data.patientName} is a {getAge(content.data.patientDOB)} year old{' '}
        {content.data.patientGender} who is here for an Initial Evaluation and Assessment for{' '}
        {renderMainProblemSelector()}. This visit was conducted {content.data.appointmentLocation}.{' '}
        {content.data.appointmentLocation === MeetingContextLocation.Telehealth && (
          <>
            The patient gave informed consent for telehealth during registration, including rights,
            services, and privacy measures, willingly participating in the virtual consultation.
          </>
        )}
      </Typography>
    </Box>
  );
};
