import { Box, TextField, Typography } from '@mui/material';
import { AppointmentSummaryContentType, MeetingSummaryStage } from 'node-api/scribe/scribe.types';
import { SectionProps } from './render-sections';

export const AppointmentSummarySection = ({
  content,
  stage,
  handlers,
}: SectionProps<AppointmentSummaryContentType>) => {
  return (
    <Box className='edit-section' mb={3}>
      <Typography mb={1} fontWeight='600'>
        Appointment summary
      </Typography>

      <Box mb={2}>
        <Typography mb={1}>Originating site:</Typography>
        <TextField
          size='small'
          disabled={stage === MeetingSummaryStage.PreVisit}
          multiline
          value={content.data.originatingSite}
          fullWidth
          onChange={(v) =>
            handlers.handleChangeAppointmentSummary({
              ...content,
              data: {
                ...content.data,
                originatingSite: v.target.value,
              },
            })
          }
        />
      </Box>

      <Box mb={2}>
        <Typography mb={1}>Distant site:</Typography>
        <TextField
          size='small'
          disabled={stage === MeetingSummaryStage.PreVisit}
          multiline
          value={content.data.distantSite}
          fullWidth
          onChange={(v) =>
            handlers.handleChangeAppointmentSummary({
              ...content,
              data: {
                ...content.data,
                distantSite: v.target.value,
              },
            })
          }
        />
      </Box>

      <Box mb={2}>
        <Typography mb={1}>Time started:</Typography>
        <TextField
          size='small'
          disabled={stage === MeetingSummaryStage.PreVisit}
          multiline
          value={content.data.startingTime}
          fullWidth
          onChange={(v) =>
            handlers.handleChangeAppointmentSummary({
              ...content,
              data: {
                ...content.data,
                startingTime: v.target.value,
              },
            })
          }
        />
      </Box>

      <Box mb={2}>
        <Typography mb={1}>Time ended:</Typography>
        <TextField
          size='small'
          disabled={stage === MeetingSummaryStage.PreVisit}
          multiline
          value={content.data.endingTime}
          fullWidth
          onChange={(v) =>
            handlers.handleChangeAppointmentSummary({
              ...content,
              data: {
                ...content.data,
                endingTime: v.target.value,
              },
            })
          }
        />
      </Box>
    </Box>
  );
};
