export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  perPage: number;
}

export enum RpmBillingStatus {
  BillableStrong = 'billable-strong',
  BillableRelevant = 'billable-relevant',
  NotBillable = 'not-billable',
  NotBillableForObesity = 'not-billable-for-obesity',
}

export enum ConditionPriority {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  BEHAVIORAL = 'behavioral',
}

export interface BillingCriteria {
  priority: ConditionPriority;
  status: RpmBillingStatus;
  excludable: boolean;
}

export enum ValidityTimeInterval {
  Days = 'days',
  Months = 'months',
  Years = 'years',
}

export enum ConditionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum LaboratoryTestStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ProcedureStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum MedicationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum LaboratoryResultSource {
  ManualInput = 'manual-input',
}

export interface Condition {
  id: number;
  name: string;
  icd10Code: string;
  description: string | null;
  status: ConditionStatus;
  includedInPvq: boolean;
  billingCriteria: BillingCriteria;
  createdAt: Date;
  updatedAt: Date;
}

export interface LaboratoryTest {
  id: number;
  name: string;
  status: LaboratoryTestStatus;
  createdAt: Date;
  updatedAt: Date;
  loincCode: string;
}

export interface Procedure {
  name: string;
  id: number;
  status: ProcedureStatus;
  createdAt: Date;
  updatedAt: Date;
  cptCode: string;
}

export interface Medication {
  id: number;
  status: MedicationStatus;
  createdAt: Date;
  updatedAt: Date;
  conceptName: string;
  ndcCode: string;
  rxCuiCode: string;
}

export interface FindAllConditionsParams {
  status?: ConditionStatus;
  page?: number;
  pageSize?: number;
}

export interface ConditionWithRelations {
  condition: Condition;
  laboratoryTests: (ConditionLaboratoryTest & { laboratoryTest: LaboratoryTest })[];
  procedures: (ConditionProcedure & { procedure: Procedure })[];
  medications: (ConditionMedication & { medication: Medication })[];
}

export type FindAllConditionsResponse = {
  conditions: ConditionWithRelations[];
  pagination: Pagination;
};

export type ConditionLaboratoryTest = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  conditionId: number;
  laboratoryTestId: number;
  validityPeriodFrom: string;
  validityPeriodUntil: string;
};

export type ConditionProcedure = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  conditionId: number;
  validityPeriodFrom: string;
  validityPeriodUntil: string;
  procedureId: number;
};

export type ConditionMedication = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  conditionId: number;
  medicationId: number;
};
