export type CPTCode = '99212' | '99213' | '99214' | '99215';

export type MedicalProblemCode = string;

export enum MedicalBehavioralProblem {
  LackOfExercise = 'Lack of exercise',
  ExerciseCounseling = 'Exercise counseling',
  DietaryCounseling = 'Dietary counseling',
}

export enum MedicalProcedure {
  MinimalNone = 'Minimal / None',
  ReviewingDiagnosticTests = 'Reviewing diagnostic tests/labs',
  ReviewingOutsideRecords = 'Reviewing outside medical records',
  OrderingDiagnosticTests = 'Ordering diagnostic tests/labs',
  InterpretingOutsideTests = 'Interpreting outside diagnostic tests or imaging',
  DiscussingPlanWithPhysician = 'Discussing the plan with outside physician',
  PrescribingMedication = 'Prescribing medication',
  ReviewingDietExerciseLog = 'Reviewing dietary and exercise log',
  ReviewingBloodPressure = 'Reviewing blood pressure',
  ReviewingHeartRateLog = 'Reviewing heart rate log',
  Counseling = 'Counseling',
  DocumentingVisit = 'Documenting the visit',
  ReviewingRecordsBeforeVisit = 'Reviewing records prior to the visit minutes',
  WritingPriorAuthorization = 'Writing a prior authorization',
  PlacingReferral = 'Placing a referral',
  ConsultingOutsideDoctor = 'Consulting with outside doctor',
  ConsultingPatientDietitian = "Consulting with patient's dietitian, exercise coach, or psychologist",
  TalkingWithFamily = 'Talking with family',
}

export enum MeetingDurationRange {
  TenToNineteen = '10-19 minutes',
  TwentyToTwentyNine = '20-29 minutes',
  ThirtyToThirtyNine = '30-39 minutes',
  FortyToSixtyFixe = '40-65 minutes',
}

export enum MDMCode {
  Straightforward = 'Straightforward',
  Low = 'Low',
  Moderate = 'Moderate',
  High = 'High',
}

export const MeetingCptCodeByDurationRange: { [key in MeetingDurationRange]: CPTCode } = {
  [MeetingDurationRange.TenToNineteen]: '99212',
  [MeetingDurationRange.TwentyToTwentyNine]: '99213',
  [MeetingDurationRange.ThirtyToThirtyNine]: '99214',
  [MeetingDurationRange.FortyToSixtyFixe]: '99215',
};
