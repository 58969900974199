import { ConditionWithRelations } from 'interfaces/health-data';
import { FC, PropsWithChildren, createContext, useCallback, useEffect, useState } from 'react';
import healthDataService from 'services/health-data';

const PER_PAGE = 30;

export type HealthDataContextValue = {
  isLoaded: boolean;
  conditions: ConditionWithRelations[];
};

const HealthDataContext = createContext<HealthDataContextValue>({
  isLoaded: false,
  conditions: [],
});

const HealthDataProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [conditions, setConditions] = useState<ConditionWithRelations[]>([]);

  const fetchConditions = useCallback(async () => {
    if (isLoaded) {
      return conditions;
    }

    let page = 1;
    let allConditions: ConditionWithRelations[] = [];

    // eslint-disable-next-line no-constant-condition
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      const response = await healthDataService.findAllConditions({
        page,
        pageSize: PER_PAGE,
      });

      if (!response) {
        break;
      }

      allConditions = allConditions.concat(response.conditions);

      if (response.conditions.length < PER_PAGE) {
        break;
      }
      page += 1;
    }

    setIsLoaded(allConditions.length > 0);
    setConditions(allConditions);
    return [];
  }, []);

  useEffect(() => {
    if (isLoaded) {
      return;
    }

    fetchConditions();
  }, [isLoaded]);

  return (
    <HealthDataContext.Provider value={{ isLoaded, conditions }}>
      {children}
    </HealthDataContext.Provider>
  );
};

export { HealthDataContext, HealthDataProvider };
